// JavaScript Document

$(function () {
	function validateInput(id) {
		var $el = $(id);
		if ($el.length) {
			var result = ($el.is(':radio') || $el.is(':checkbox')) ? $el.is(':checked') : $el.val();
			$el.closest('.form-group').toggleClass('has-error', !result);
			return result ? 0 : 1; // error count
		}
		return 0; // not exists
	}
	
	$('#register-form').submit(function(e) {
		var errorCount = 0;
		
		errorCount += validateInput('#card-number');
		errorCount += validateInput('#first-name');
		errorCount += validateInput('#last-name');
		errorCount += validateInput('#email');
		errorCount += validateInput('#password');
		errorCount += validateInput('#agree-rules');
		errorCount += validateInput('#agree-email');
		
		if (errorCount > 0) {
			e.preventDefault();
		}
	});
	$('#register-form-submit').click(function(e) {
		$('#register-form').submit();
	});
});