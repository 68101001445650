$ = jQuery

class Facebook

  @setup: ->
    @load()
    console.log('FB setup')
    $(document).on "click", "[data-facebook-connect]", @onClick

  @onClick: (event) ->
    new Facebook $(event.currentTarget)

  @initializeSDK: ->
    console.log('FB init')
    FB.init
      appId      : $("meta[name=fb_app_id]").attr("content")#@appId(),
      xfbml      : true,
      version    : 'v2.8'
    console.log(FB)

  @load: ->
    window.fbAsyncInit = @initializeSDK
    js = document.createElement('script')
    js.src = "//connect.facebook.net/pl_PL/all.js"
    document.body.appendChild(js)

  constructor: (element) ->
    @element = element
    @connect()


  connect: ->
    console.log('FB login')
    FB.login(
      (response) =>
        if ((response.status == "connected") and (response.authResponse))
          @createSession(response.authResponse)
      scope: 'user_about_me, email',
      auth_type: 'rerequest'
    )

  url: ->
    @element.data("url")

  appId: =>
    $("meta[name=fb_app_id]").attr("content")

  createSession: (authResponse) ->
    $('#facebook-connect-form-access-token').val(authResponse.accessToken)
    $('#facebook-connect-form').attr('action', @url())
    if (@url() == "/facebookRegister")
      $('#client-type').val($('#register input[name=client-type]:checked').val())
      $('#card-other-type').val($('#register select[name=card-other-type]').val())
    $('#facebook-connect-form').submit()

Facebook.setup()
