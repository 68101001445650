jQuery(document).ready(function($){
  function loadmap(){
    var myLatLng = {lat:52.2354923,lng:19.4896616};
    var styles = [{"featureType":"water","elementType":"geometry.fill","stylers":[{"color":"#d3d3d3"}]},{"featureType":"transit","stylers":[{"color":"#808080"},{"visibility":"off"}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"visibility":"on"},{"color":"#b3b3b3"}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"road.local","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"weight":1.8}]},{"featureType":"road.local","elementType":"geometry.stroke","stylers":[{"color":"#d7d7d7"}]},{"featureType":"poi","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#ebebeb"}]},{"featureType":"administrative","elementType":"geometry","stylers":[{"color":"#a7a7a7"}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"landscape","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#efefef"}]},{"featureType":"road","elementType":"labels.text.fill","stylers":[{"color":"#696969"}]},{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"visibility":"on"},{"color":"#737373"}]},{"featureType":"poi","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"poi","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"road.arterial","elementType":"geometry.stroke","stylers":[{"color":"#d6d6d6"}]},{"featureType":"road","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{},{"featureType":"poi","elementType":"geometry.fill","stylers":[{"color":"#dadada"}]}];

    var map = new google.maps.Map(document.getElementById('allclubs-map'), {
      zoom:6,
      center: myLatLng,
      //disableDefaultUI: true,
      styles: styles,
      controls: {
        panControl: false,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        overviewMapControl: false
      },
      scrollwheel: false,

    });

    var markerColor = $('#map_color').val();
    var image = (markerColor=='fitmisja')?"/images/marker-yellow.png":"/images/marker.png";

    function initMarkers(map, markerData) {
      var newMarkers = [],
      marker;
      for(var i=0; i<markerData.length; i++) {
        marker = new google.maps.Marker({
          map: map,
          draggable: false,
          position: markerData[i].latLng,
          visible: true,
          icon: image
        }),
        boxText = document.createElement("div"),
        infoboxOptions = {
          content: boxText,
          disableAutoPan: false,
          maxWidth: 0,
          pixelOffset: new google.maps.Size(-140,10),
          zIndex: null,
          boxStyle: {
            background: "#fff",
            border: "1px solid #A6CF45",
            padding: "10px",
            opacity: 0.85,
            width: "280px"
          },
          closeBoxMargin: "0px",
          closeBoxURL: "http://www.google.com/intl/en_us/mapfiles/close.gif",
          infoBoxClearance: new google.maps.Size(1, 1),
          isHidden: false,
          //pane: "floatPane",
          enableEventPropagation: false
        };


        // add a click listener to the marker
        google.maps.event.addListener(marker, 'click', function() {
          // reference clicked marker
          var curMarker =  this;
          // loop through all markers
          jQuery.each(markers, function(index, marker) {
            // if marker is not the clicked marker, close the marker
            if(marker !== curMarker) {
              marker.infobox.close();
            }
          });
        });

        newMarkers.push(marker);
        boxText.style.cssText = "";
        boxText.innerHTML = "<div class='mapoverlay'><h6><a href='"+markerData[i].club+"'>" + markerData[i].name + "</a></h6><p>" + markerData[i].address + "<br /><a href='"+markerData[i].club+"'>strona klubu</a> <span>|</span> <a href='"+markerData[i].directto+"' target=\"_blank\">nawiguj</a></p></div>";
        newMarkers[i].infobox = new InfoBox(infoboxOptions);
        //newMarkers[i].infobox.open(map, marker);
        google.maps.event.addListener(marker, 'click', (function(marker, i) {
          return function() {
            map.setZoom(12);
            newMarkers[i].infobox.open(map, this);
            map.panTo(markerData[i].latLng);
          }
        })(marker, i));
      }
      return newMarkers;
    }


    var clusterStylesGreen = [
      {
        textColor: '#fff',
        url: '/images/one.png',
        height: 65,
        width: 65
      },
      {
        textColor: '#fff',
        url: '/images/one.png',
        height: 65,
        width: 65
      },
      {
        textColor: '#fff',
        url: '/images/one.png',
        height: 65,
        width: 65
      },
    ];

    var clusterStylesYellow = [
        {
            textColor: '#fff',
            url: '/images/two.png',
            height: 65,
            width: 65
        },
        {
            textColor: '#fff',
            url: '/images/two.png',
            height: 65,
            width: 65
        },
        {
            textColor: '#fff',
            url: '/images/two.png',
            height: 65,
            width: 65
        },
    ];

      var clusterStyles = (markerColor=='fitmisja')?clusterStylesYellow:clusterStylesGreen;


      var mcOptions = {
      gridSize: 50,
      styles: clusterStyles,
      maxZoom: 13
    };

    $.ajax({
      method: 'GET',
      url: 'starter/clubList',
      success: function(clubList){
        var markerData2 = [];
        for (i = 0; i < clubList.length; i++) {
          if (clubList[i]['lat'] != '' && clubList[i]['lng'] != '') {
            markerData2.push( { latLng: new google.maps.LatLng(clubList[i]['lat'],clubList[i]['lng']),
            name: clubList[i]['name'],
            address: clubList[i]['zip_code']+','+clubList[i]['city']+' '+clubList[i]['street_name']+' '+clubList[i]['street_number']+'<br/>'+clubList[i]['phone_1']+'<br/>',
            directto:  "https://maps.google.com?saddr=Current+Location&daddr="+clubList[i]['lat']+","+clubList[i]['lng'],
            club: clubList[i]['website_address']} );
          }
        }
        markers = initMarkers(map, markerData2);
        var markerCluster = new MarkerClusterer(map, markers,mcOptions);

        google.maps.event.addListenerOnce(map, 'idle', function() {
          google.maps.event.trigger(map, 'resize');
        });
      }
    });
};

$(window).on('scroll', function(){
  //animatecounter
  var mapcont = $('#allclubs-map');
  if(mapcont.length>0){
    if(mapcont.hasClass('loaded'));
    else {
      var scrolledToBottom = (window.innerHeight + window.scrollY);
      if(scrolledToBottom-100>mapcont.offset().top){
        loadmap();
        mapcont.addClass('loaded');
      }
    }
  }
});
});
