/*animacja funkcja*/
function anim_btn_f(element,text,textalt) {
	var text = $(element).attr('text');
	var textalt = $(element).attr('textalt');
	$(element).find("span").animate({opacity:0},1000,function () {
		$(this).html(text).animate({opacity:1},2000,function () {
			$(this).animate({opacity:0},1000,function () {
				$(this).html(textalt).animate({opacity:1},2000, function () {
                    anim_btn_f(element);
                });
            });
        });
    });
}

function checkDays() {
    if ($('input[name$="invoice"]').is(':enabled')) {
        $('#invoice').removeClass('hidden');
    }
    var agreement = $('#agreement_6');
    if (agreement.length > 0) {
        if(!document.getElementById('agreement_6').checked){
            $('#daysModal').modal('show');
            return false;
        }
    }
    $('#phone').unmask();
    // $('#birthDate').unmask();
    var pass = $('#password').val();
    $("#repeated_password").val(pass);
}

   function makeSure(){
       if($('#freed_add').hasClass('btn-primary')) {
        $('#makeSureFeeze').modal('show');
         return false;
       }
    }
    $(function () {
    $('body').on('click', '.freeze-submit', function (e) {
        $(this.form).submit(); 
        $('#makeSureFreeze').modal('hide');
    });
}); 

$(document).ready(function(){

$('#popupModal').modal('show');
$('#rodoModal').modal('show');

anim_btn_f('#anim_btn');
anim_btn_f('#anim_btn2');

var options = {
    onKeyPress: function(cep, e, field, options) {
        var masks = ['000-000-0000', '(00) 000-000-0000', '(00) 0000-000-000-000'];
        console.log(cep.length);
        var mask = (cep.length < 12) ? masks[0] : cep.length < 17 ? masks[1] : masks[2];
        $('#phone').mask(mask, options);
    }};
$('#phone').mask('000-000-0000', options);

$('#pesel').blur(function() {
    var pesel = $(this).val();
    var is_adult = $('#is_adult').val();
    if(pesel) {
        //pobranie daty
        var rok     = parseInt(pesel.substring(0,2),10);
        var miesiac = parseInt(pesel.substring(2,4),10);
        var dzien   = parseInt(pesel.substring(4,6),10);

        if(miesiac > 80)
        {
            rok += 1800;
            miesiac = miesiac - 80;
        }
        else if(miesiac > 60)
        {
            rok += 2200;
            miesiac = miesiac - 60;
        }
        else if (miesiac > 40)
        {
            rok += 2100;
            miesiac = miesiac - 40;
        }
        else if (miesiac > 20)
        {
            rok += 2000;
            miesiac = miesiac - 20;
        }
        else
        {
            rok += 1900;
        }
    }
    throwErr(rok,miesiac,dzien);
});

$('#birthDate').blur(function() {
    var data_urodzenia = $(this).val();
    var rok     = parseInt(data_urodzenia.substring(0,4),10);
    var miesiac = parseInt(data_urodzenia.substring(5,7),10);
    var dzien   = parseInt(data_urodzenia.substring(8,10),10);
    throwErr(rok,miesiac,dzien);
});

function throwErr(rok,miesiac,dzien){
    var is_adult = $('#is_adult').val();
    var dzis = new Date();
    var dataUrodzin18 = new Date(rok+18, miesiac-1, dzien);
    var dataUrodzin16 = new Date(rok+16, miesiac-1, dzien);
    var dataUrodzin67 = new Date(rok+67, miesiac-1, dzien);
    if (is_adult && (dataUrodzin67 <= dzis)){
        $('#yearsModal').modal('show');
        return false;
    } else {
        if (is_adult && (dataUrodzin18 > dzis)) {
            $('#yearsModal').modal('show');
            return false;
        } else if (dataUrodzin16 > dzis) {
            $('#years16Modal').modal('show');
            return false;
        }
    }
}

// $('#contract').on('submit', function() {
//     $('#submitBtn').attr('disabled','disabled');
// });

$(function () {
    $('body').on('click', '.odom-submit', function (e) {
        $(this.form).submit();
        var pass = $('#password').val();
        $("#repeated_password").val(pass);
        $('#daysModal').modal('hide');
    });
});


function arrayRemove(arr, value) {

   return arr.filter(function(ele){
       return ele != value;
   });
}

    $('.club_brand').change(
        function () {
            var pathArray = window.location.pathname.split('/');
            var newPathName = '';
            const params = new URLSearchParams(location.search);
            params.delete('city');
            params.delete('club');

            if ($('.club_brand option:selected').attr('data-redirect-target') == 'Calypso' && pathArray.includes('elford')) {
                pathArray = arrayRemove(pathArray, 'elford');
                newPathName = pathArray.join('/');
                params.append('club', $('.club_brand option:selected').text());
                params.append('city', $("#club_city").val());
                location = newPathName + '?' + params.toString();
            } else if ($('.club_brand option:selected').attr('data-redirect-target') == 'Elford' && !(pathArray.includes('elford'))) {
                console.log('Elford');
                pathArray = arrayRemove(pathArray, '');
                pathArray.unshift("elford");
                newPathName = pathArray.join('/');
                params.append('club', $('.club_brand option:selected').text());
                params.append('city', $("#club_city").val());
                location = '/' + newPathName + '?' + params.toString();
            } else {
                params.append('club', $('.club_brand option:selected').text());
                params.append('city', $("#club_city").val());
                location.search = params.toString();
            }
        }
    );

    $('.club_city').change(
        function () {
            var club_city = $("#club_city").val();
            $.ajax({
                method: 'GET',
                url: 'login/refreshClubList',
                data: {
                    club_city: club_city
                },
            });
        });
    
    $('.oksystem_club_city').change(
        function () {
            var club_city = $("#club_city").val();
            $.ajax({
                method: 'GET',
                url: 'oksystem/refreshClubList',
                data: {
                    club_city: club_city
                },
            });
        });
        
      $('.fitpresent19_club_city').change(
        function () {
            var club_city = $("#club_city").val();
            $.ajax({
                method: 'GET',
                url: 'fitPresent19/refreshClubList',
                data: {
                    club_city: club_city
                },
            });
        });    
        
        
        
    $('#register-mode').on('click', function (e) {
           $("#login-mode-form").addClass("hidden");
           $("#register-mode-form").removeClass("hidden");
           $("#register-mode").removeClass("text-muted");
           $("#login-mode").addClass("text-muted");
    });
     $('#login-mode').on('click', function (e) {
           $("#login-mode-form").removeClass("hidden");
           $("#register-mode-form").addClass("hidden");
           $("#login-mode").removeClass("text-muted");
           $("#register-mode").addClass("text-muted");
    });
    
      
    $("#pwdReset a").on('click',function(event) {

      $("#login-mode-form").validator();
      var validator = $("#login-mode-form").data("bs.validator");
      validator.validate();
      
         if (!validator.hasErrors()) 
         {
              $("login-mode-form").submit();
         } else 
         {
            event.preventDefault();
         }
    }); 
    
});