/* ========================================================================== *
 * Created by Neubloc
 * Developer: Magdalena Szczerbowska
 * Application Scripts
 * ========================================================================== */

$("#groupsList").on("change", function () {
  var groupId = $('#groupsList').val();
  var params={};
  window.location.search
    .replace(/[?&]+([^=&]+)=([^&]*)/gi, function(str,key,value) {
      params[key] = value;
    }
  );
  var form = $('<form method="GET" action="' + window.location.href + '"></form>');
  var metadataInput = '<input name="group_id" value="' + groupId + '" type="hidden" />';
  if (params["all"] !== undefined) {
    metadataInput = metadataInput + '<input name="all" value="' + params["all"] + '" type="hidden" />';
  }
  form.hide().append(metadataInput).appendTo('body');
  form.submit();
});
