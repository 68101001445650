jQuery(function(){

	var menuopen = false;
	
	jQuery('#land-mobSwitch').click(function(){
		if(menuopen){
			jQuery('#land-mobileMenu').animate({left: "-300px"}, 250);
			menuopen = false;
		} else {
			jQuery('#land-mobileMenu').animate({left: "0px"}, 250);
			menuopen = true;
		}
	});
	
	jQuery('#land-mobileMenu span').click(function(){
			jQuery('#land-mobileMenu').animate({left: "-300px"}, 250);
			menuopen = false;
	});
});

var langOpen = false;
$('.oneLang.cur').click(function(){
    if(langOpen){
        $('.oneLang.hid').fadeOut(100);
        langOpen = false;
    } else {
        $('.oneLang.hid').fadeIn(100);
        langOpen = true;
    }
});

function goTo(idnam){
		var offsetVal = jQuery(idnam).offset().top;
		var offsetAnim = offsetVal-96;
		
		jQuery('html, body').delay(100).animate({
			scrollTop: offsetAnim
		}, 1000);
	}
	
	
	
jQuery.fn.isOnScreen = function(){
    
    var win = jQuery(window);
    
    var viewport = {
        top : win.scrollTop()-200,
        left : win.scrollLeft()
    };
    viewport.right = viewport.left + win.width();
    viewport.bottom = viewport.top + win.height();
    
    var bounds = this.offset();
    bounds.right = bounds.left + this.outerWidth();
    bounds.bottom = bounds.top + this.outerHeight();
    
    return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
    
};


