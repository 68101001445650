
/* ========================================================================== *
 * Created by Neubloc
 * Developers: Kamil Prus & Magda Szczerbowska
 * Application Scripts
 * ========================================================================== */
$(window).load(function () {
    ($("[data-behavior~=radio-toggle]").each(function () {
        var condition = $("input:checked", this).val();
        if (condition == "true" || condition == "karta") {
            $($(this).attr("data-target")).show();
        } else {
            $($(this).attr("data-target")).hide();
        }
    }));

});

//przełaczanie widoczności - klasa hidden
function _showElem() {
  var $this = $(this);
  var $target = $($this.data('show-element'));
  //console.log($target);
  if ($this.is(':checked')) $target.removeClass('hidden');
}

function _showElemClick() {
  var $this = $(this);
  var $target = $($this.data('show-element'));
  $target.removeClass('hidden');
}

function _hideElem() {
  var $this = $(this);
  var $target = $($this.data('hide-element'));
  if ($this.is(':checked')) $target.addClass('hidden');
}

function _hideElemClick() {
  var $this = $(this);
  var $target = $($this.data('hide-element'));
  $target.addClass('hidden');
}

$('[data-show-element]').each(_showElem);
$('input[data-show-element]').change(_showElem);
$('a[data-show-element]').click(_showElemClick);
$('button[data-show-element]').click(_showElemClick);

$('[data-hide-element]').each(_hideElem);
$('input[data-hide-element]').change(_hideElem);
$('a[data-hide-element]').click(_hideElemClick);
$('button[data-hide-element]').click(_hideElemClick);

/* ------- bootstrap-select ------- */

$('.selectpicker').selectpicker({
    container: 'body'
});


$('#photo').on('change', function () {
    var reader = new FileReader();
    reader.onload = function (e) {
        $('#avatar').attr('src', e.target.result);
        var filename = $('input[type=file]').val().split('\\').pop();
        $('#photo_name_text').text(filename);
        $('#photo_name').val(filename);
        //$('input[name=photo_name]').val(filename);
    };
    reader.readAsDataURL(this.files[0]);
});

$('#btn_delete_photo').on('click', function () {
    $('#delete_photo').val('true');
    $('#avatar').attr('src', $('#avatar').attr('default'));
});

$(".nav-top-menu .search").on('click', function(ev) {
  if ( $(ev.target).is('.search-icon') ) {
    $.ajax({
    method: 'GET',
    url: 'schedule/clubList',
    data: {

     },
   });
	}
});

$('#trainingClubId').ready( function() {
  if ($('#trainingClubId').length){
    findCoaches();
  }
});

$('#trainingClubId').change( function() {
  $("#select-coaches").prop("disabled", true);
  findCoaches();
});

function findCoaches() {
  $("#select-coaches").prop("disabled", true);
  var clubId = $("#trainingClubId").val();
    $.ajax({
    method: 'GET',
    url: 'training/findCoaches',
    data: {
      clubId: clubId
     },
   });
}

$('#select-coaches').change(function () {
  var coachId = $('#select-coaches').val();
  var clubId = $("#trainingClubId").val();
  if (coachId=="All" || coachId=="Wszyscy") {
      findCoaches();
    } 
    else {
    $.ajax({
    method: 'GET',
    url: 'training/findCoach',
    data: {
      clubId: clubId,
      coachId: coachId
     },
   });
   } 
   $('#coach-toggle-'+coachId).click();
});

$('#courts-archive').click( function() {
    var archive = ($('#courts-archive').is(':checked')) ? '/archive' : '';
    showReservationList('courts/reservations', archive);
});

$('#training-archive').click( function() {
    var archive = ($('#training-archive').is(':checked')) ? '/archive' : '';
    showReservationList('training/reservations', archive);
});

function showReservationList(type, archive) {
  $.ajax({
  method: 'GET',
  url: type +  archive,
  data: {
   },
  });
}

$('#print').click( function() {
  window.print();
});

function isEmpty(obj) {
  for (var prop in obj) {
    if(obj.hasOwnProperty(prop))
      return false;
  }
  return JSON.stringify(obj) === JSON.stringify({});
}

function fireEvent($popup) {
  var params = {
    id: $popup.data('id'),
    date: $popup.data('date'),
    time: $popup.data('time'),
    popup: $popup
  };
  $popup.trigger('trainerChange', params);
}

function initializeReservationPopup($popup) {
		$popup.on('shown.bs.collapse', function(ev) {
			ev.stopPropagation();
			var id = $popup.find('.trainer-info.collapse.in').attr('id');
			$popup.data('id', id);
			fireEvent($popup);
		});

		$popup.on('dp.change', function(ev) {
      ev.stopPropagation();
			$popup.data('date', ev.date);
			fireEvent($popup);
		});

		$popup.on('slideStop', function(ev) {
			ev.stopPropagation();
			if (isEmpty(ev.target)) return;
			$popup.data('time', ev.value);
			fireEvent($popup);
		});
}

$('#select-activity').change( function() {
  findCourtClub();
});

function findCourtClub() {
  $("#courts-club-select").prop("disabled", true);
  var activityType = $("#select-activity").val();
    $.ajax({
    async: false,
    method: 'GET',
    url: 'courts/findClubs',
    data: {
      activityType: activityType
     },
   });
}

$('#start_date').on('dp.error', function(e) {
  //console.log('ERROR');
});

var locale = window.location.pathname.split('/')[1];
var minDate = moment().startOf('day');
var maxDate = moment(minDate).add('2','week');

$('#courts_date_from').datetimepicker({
    locale: locale,
    format: 'DD-MM-YYYY',
    minDate: minDate,
    maxDate: maxDate
});
$('#courts_date_to').datetimepicker({
    locale: locale,
    format: 'DD-MM-YYYY',
    minDate: minDate,
    maxDate: maxDate
});

$('#cart_start_date').on('dp.change', function(e) {
  var new_date = e.date;
  var duration = $('#duration').val();
  $('#date_summary').html(new_date.format('DD-MM-YYYY'));
  new_date.add(duration, 'month');
  $('#date_expired').html(new_date.format('DD-MM-YYYY'));
});

//cart - invoice
$('input[name=invoice]').click(function () {
 $('#invoice').show();
 $('#invoice').removeClass('hidden');
});

$("#contract_data_start").change();

$("#contract_data_start").on('change', function () {
    $("#contract_data_start_hdn").val($(this).val());
});

$("#payment_options").click();

$("#discount_code").on({
    keydown: function(e) {
        if (e.which === 32)
            return false;
    },
    change: function() {
        this.value = this.value.replace(/\s/g, "");
    }
});

$('#start_date').on('dp.change', function (e) {
  var start_date = $('[name=start_date]').val().split('T');
  var delivery = $('#transport_id_club').hasClass('hidden') ? '1' : '0';
  $.ajax({
  async: false,
  method: 'GET',
  url: 'contract/refreshSummary',
  data: {
      start_date: start_date[0],
      delivery: delivery
   }
 });
 $('#insurance_to').text($('#insurance_from').text());
});

$('#ticketGym_start_date').on('dp.change', function (e) {
  var start_date = $('#ticketGym_start_date').val().split('T');
  $.ajax({
  async: false,
  method: 'GET',
  url: 'ticketGym/refreshSummary',
  data: {
      start_date: start_date[0],
   }
 });
});

$('#contract_discount').click(function () {
 var discount_code = $('#discount_code').val();
 var start_date = $('[name=start_date]').val().split('T');
 var delivery = $('#transport_id_club').hasClass('hidden') ? '1' : '0';
 console.log('contract discount');
  $.ajax({
  async: false,
  method: 'GET',
  url: 'contract/refreshSummary',
  data: {
      start_date: start_date[0],
      delivery: delivery,
      discount_code:discount_code,
   }
 });
});


$('#ticket_discount').click(function () {
 var discount_code = $('#discount_code').val();
  $.ajax({
  async: false,
  method: 'GET',
  url: 'cart/refreshSummary',
  data: {
      discount_code:discount_code,
   }
 });
});

$('#discount_code').click( function( e ) {
    $('#code_info').empty();
});


//odświeżanie podsumowanie po zmianie sposobu dostawy
$('[name=transport]').change( function( e ) {
  e.stopPropagation();
  var $this = $(this);
  var start_date = $('#start_date').val().split('T');
  var delivery = $('#transport_id_club').hasClass('hidden') ? '1' : '0';
  $('#trasport_info_1').hasClass('hidden') ? $('#trasport_info_1').removeClass('hidden') : $('#trasport_info_1').addClass('hidden');
  $('#trasport_info_2').hasClass('hidden') ? $('#trasport_info_2').removeClass('hidden') : $('#trasport_info_2').addClass('hidden');
  $.ajax({
  async: false,
  method: 'GET',
  url: 'contract/refreshSummary',
  data: {
      start_date: start_date[0],
      delivery: delivery
   },
 });
 fireEvent($this);
});

//kopiuj/czyść dane do faktury
$('#contractSubmit').click( function(e) {
  e.stopPropagation();
  var $this = $(this);
  if ($('[name=dane]:checked').val() == "1") {
    jQuery.each(['#firstName', '#lastName', '#street', '#buildingNo', '#flatNo', '#zipcodeInput', '#city'], function(idx, value) {
      $('#invoice '+value).val($('#contract '+value).val());
    });
    var invoiceCity = $('#invoice, #city').val();
    if (invoiceCity) {
      if ($('#invoice #city2').find("option[value='" + invoiceCity + "']").length) {
        $('#invoice #city2').val(invoiceCity).trigger('change');
      } else {
        // Create a DOM Option and pre-select by default
        var newOption = new Option(invoiceCity, invoiceCity, true, true);
        // Append it to the select
        $('#invoice #city2').append(newOption).trigger('change');
      }
    }
  }
  //console.log('copied');
  fireEvent($this);
});

//kolejne kroki dla koszyka/umowy po naciśnięciu przycisku "dalej"
$('#contractContinue').click( function( e ) {
  e.preventDefault();
  var next_step = $(this).attr('next-step');
  var hide_button = $('[step='+next_step+']').attr('hide-button');
  var last_step = $('[step='+next_step+']').attr('last-step');
  if (hide_button == "true") {
    $(this).addClass('hidden');
  } else {
    $(this).removeClass('hidden');
  }
  $('[step='+next_step+']').removeClass('hidden');
  $(this).attr('next-step', (+next_step + 1) );
  if (last_step == "true") {
    $(this).addClass('hidden');
    $('#contractSubmit').removeClass('hidden');
  }
    $('html, body').animate({
        scrollTop: $('#scroll' + next_step).offset().top
    }, 1000);

    if($('#contractSubmit').is(':visible')) {
        $('#back_old').hide();
    }
});

$( "input[name='client-type']" ).click(function() {
    if($('#card-other').is(':checked')){
        $("select[name='card-other-type']" ).prop('required',true);
    } else {
        $("select[name='card-other-type']" ).prop('required',false);
    }
    if($('#card-starter').is(':checked')){$('#facebook-connect-form-a').hide();}else{$('#facebook-connect-form-a').show();}
    $('#client-type').val($(this).val());
});


$( "select[name='client-and-card-type-select']" ).on('change', (function() {
    if($('#new-client').is(':selected') || $('#card-calypso').is(':selected')  ) {
        $('#client-type').val($(this).val());
        $('#card-other-type').val("");
        $('#client-type-s').val($(this).val());
        $('#card-other-type-s').val("");
    } else {
        $('#client-type-s').val("kOtherClient");
        $('#card-other-type-s').val($(this).val());
        $('#client-type').val("kOtherClient");
        $('#card-other-type').val($(this).val());
    }   
    if($('#card-starter').is(':selected')){$('#facebook-connect-form-a').hide();}else{$('#facebook-connect-form-a').show();}    
}));

$(document).ready(function() {
    $('#client-and-card-type-select').change();
});


$('#register-id').click(function () {
    if($('#card-starter').is(':checked')){
        $("#register").submit(function(e){
            e.preventDefault();
        });
        window.location.href ='/starter';
    } else {
        return true;
    }
});

$( "#card-other-select" ).change(function() {
    $('#card-other-type').val($(this).val());
    
});

$('#humanCheck').change( function() {
    $('#humanDisable').prop('disabled', function () {
        return ! $(this).prop('disabled');
    });
});

$('#land-cookieClose').click(function() {
    $('#land-cookieInfo').addClass('hidden');

} );

$('#accordion').click(function(){
   // $("#resign_toggle").toggle();
   // $("#resign_arrow").toggleClass('fa-angle-up fa-angle-down')
    $("#resign_toggle").hasClass('hidden') ? $("#resign_toggle").removeClass('hidden') : $("#resign_toggle").addClass('hidden');
    $("#resign_arrow").hasClass('fa-angle-down') ?  $("#resign_arrow").addClass('fa-angle-up').removeClass('fa-angle-down') : $("#resign_arrow").removeClass('fa-angle-up').addClass('fa-angle-down');
});


$('#accordion2').click(function(){
    $("#resign_arrow2").hasClass('fa-angle-down') ?  $("#resign_arrow2").addClass('fa-angle-up').removeClass('fa-angle-down') : $("#resign_arrow2").removeClass('fa-angle-up').addClass('fa-angle-down');
});


$('#reason').on('select2:select', function (e) {
    $('#freed_add').removeClass('btn-default').addClass('btn-primary');
});

$('#reason2').on('select2:select', function (e) {
    $('#resign_add').removeClass('btn-default').addClass('btn-primary');
});


var current_date = moment();
var minimalDate = (current_date > "2019/12/26"  ? current_date : "2019/12/26");

$('#fitpresent19_start_date').datetimepicker({
    minDate:'2021/12/26',
    maxDate:'2022/03/31',
    format:'YYYY-MM-DD'
});



$('#skew_club span:first').addClass('skew');

$('#insuranceAgreementMore').click( function( e ) {
     e.preventDefault();
});


$(document).ready(function(){

    //open mobile menu
    $('.okpack-nav-burger').click(function(){
        $(this).toggleClass('active');
        $('.okpack-nav-mobile-menu').toggleClass('active');
    });

    //scroll down to section on menu click
    $('.okpack-nav-link').click(function (e) {
        $('.okpack-nav-mobile-menu').removeClass('active');
        $('.okpack-nav-burger').removeClass('active');
        var linkHref = $(this).attr("href");
        var idElement = linkHref.substr(linkHref.indexOf("#"));
        $("html, body").animate(
            {
                scrollTop: $(idElement).offset().top - 100
            },
            500
        );
        return false;
    });

});


$(document).ready(function(){

    //open mobile menu
    $('.fitprezent-nav-burger').click(function(){
        $(this).toggleClass('active');
        $('.fitprezent-nav-mobile-menu').toggleClass('active');
    });

    //scroll down to section on menu click
    $('.fitprezent-nav-link').click(function (e) {
        $('.fitprezent-nav-mobile-menu').removeClass('active');
        $('.fitprezent-nav-burger').removeClass('active');
        var linkHref = $(this).attr("href");
        var idElement = linkHref.substr(linkHref.indexOf("#"));
        $("html, body").animate(
            {
                scrollTop: $(idElement).offset().top - 100
            },
            500
        );
        return false;
    });

});



